import { useSelector } from 'react-redux';
import { colors, CompanyAttributes, TeacherAttributes } from '../utils/constants';
import { RootState } from '../store/configureStore';
import { find } from 'lodash';
import ClassTimeDisplayTable from '../components/table/ClassTimeDisplayTable';
import { css } from '@emotion/react';

interface StateCompanies extends CompanyAttributes {
  teachers: TeacherAttributes[]
}

const ViewCss = css`
  display: grid;
  padding: 2rem;
  width: 100%;
  background-color: ${colors.grayBlue};
`

const MyClassTimesContainer = () => {
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company },
    account: { companies },
  } = state;
  const current_teacher = find(find(companies as StateCompanies[], (co: StateCompanies) => co.id === company)?.teachers, t => t.teacher_company_id.toString() === company.toString())

  if (!current_teacher) {
    return (<h1>Não há registro de professor para essa conta</h1>)
  }
  return (
    <div css={ViewCss}>
      <span className='title'>Meus Horários</span>
      <ClassTimeDisplayTable  teacher_id={current_teacher.id}/>
    </div>
  );
};

export default MyClassTimesContainer;
