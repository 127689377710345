import React from 'react';
import { css } from '@emotion/react';
import {
  colors,
  CurriculumAttributes,
  KtwelveCurriculumAttributes,
} from '../../../utils/constants';
import { FETCH_CURRICULUM } from '../../../store/curriculums';
import { useDispatch } from 'react-redux';
import { error } from 'react-notification-system-redux';
import { reduce } from 'lodash';
import { Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import TooltipButton from '../../shared/TooltipButton';
import PenIcon from '../../icon/PenIcon';
import Loading from '../../loading/Loading';
import KtwelveSubjectTable from '../../table/KtwelveSubjectsTable';
import { Visibility } from '@mui/icons-material';
import KtwelveCurriculumsFormContainer from '../../../containers/KtwelveCurriculumsFormContainer';

const WraperCss = css`
  flex: 1;
`;

const TableCss = css`
  & .include {
    display: flex;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  & .actionsIcon {
    color: ${colors.darkGrayBlue};
    cursor: pointer;
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
    & tbody > tr {
      background: white;
    }
  }
`;


const KtwelveStep = (props: { curriculum: CurriculumAttributes }) => {
  const { curriculum } = props;
  const dispatch = useDispatch();
  const [ktwelve_curriculums, setKtwelveCurriculms] = React.useState<KtwelveCurriculumAttributes[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [expandedKtwelveSubjects, setExpandedKtwelveSubjects] = React.useState<number[]>([]);
  const [editKtwelveCurriculum, setEditingKtwelveCurriculum] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);

  const fetchKtwelveCurriculums = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        FETCH_CURRICULUM.request({
          id: curriculum.id,
          params: {
            filters: {
              include: 'ktwelve_curriculums',
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      const ktwelve_curriculum_attributes = reduce(
        included,
        (acc, incl) => {
          if (incl.type === 'ktwelve_curriculums') {
            return acc.concat({ id: incl.id, ...incl.attributes });
          }
          return acc;
        },
        [] as KtwelveCurriculumAttributes[],
      );
      setKtwelveCurriculms(ktwelve_curriculum_attributes);
      setLoading(false);
    } catch (er) {
      dispatch(
        error({
          message: 'Erro ao carregar séries da matriz curricular',
          autoDismiss: 3,
        }),
      );
    }
  };

  const init = async () => {
    await fetchKtwelveCurriculums();
  };

  React.useEffect(() => {
    init();
  }, []);

  const handleSavingWhenCreate = async () => {
    setCreate(false);
    await init();
  };

  const columns = ['Nome', 'Rótulo'];
  if (loading) {
    return <Loading />;
  }
  return (
    <div css={WraperCss}>
      <div css={TableCss}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell align='left' key={item}>
                    {item}
                  </TableCell>
                ))}
                <TableCell align='right'>
                  <button disabled={editKtwelveCurriculum.length > 0} onClick={() => setCreate(!create)} className='green small'>
                    <span> Adicionar série </span>
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                  <Collapse in={create} timeout='auto' unmountOnExit>
                    <KtwelveCurriculumsFormContainer
                      close_form={() => setCreate(false)}
                      onSave={handleSavingWhenCreate}
                      curriculum={curriculum}
                      all_ktwelve_curriculums={ktwelve_curriculums}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
              {ktwelve_curriculums.map((ktwelve_curriculum) => {
                const close_editing_form = () => {
                  setEditingKtwelveCurriculum((current) => current.filter((item) => item !== ~~ktwelve_curriculum.id));
                };
                return (
                  <React.Fragment key={ktwelve_curriculum.id}>
                    <TableRow>
                      <TableCell align='left'>{ktwelve_curriculum.ktwelve_degree_name}</TableCell>
                      <TableCell align='left'>{ktwelve_curriculum.label}</TableCell>
                      <TableCell align='right'>
                        <TooltipButton
                          tooltipProps={{
                            title: expandedKtwelveSubjects.includes(~~ktwelve_curriculum.id) ? 'Ocultar disciplinas' : 'Ver disciplinas',
                          }}
                          Icon={Visibility}
                          iconProps={{ style: { color: colors.blue } }}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedKtwelveSubjects((current) => {
                                if (current.includes(~~ktwelve_curriculum.id)) {
                                  return current.filter((item) => item !== ~~ktwelve_curriculum.id);
                                } else {
                                  return current.concat(~~ktwelve_curriculum.id);
                                }
                              }),
                          }}
                        />
                        <TooltipButton
                          tooltipProps={{
                            title: editKtwelveCurriculum.includes(~~ktwelve_curriculum.id)
                              ? 'Cancelar edição'
                              : 'Editar série',
                          }}
                          Icon={PenIcon}
                          iconProps={{ style: { color: colors.blue } }}
                          iconButtonProps={{
                            disabled:
                              create ||
                              (editKtwelveCurriculum.length > 0 && !editKtwelveCurriculum.includes(~~ktwelve_curriculum.id)),
                            onClick: () =>
                              setEditingKtwelveCurriculum((current) => {
                                if (current.includes(~~ktwelve_curriculum.id)) {
                                  return current.filter((item) => item !== ~~ktwelve_curriculum.id);
                                } else {
                                  return current.concat(~~ktwelve_curriculum.id);
                                }
                              }),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 2}>
                        <Collapse in={editKtwelveCurriculum.includes(~~ktwelve_curriculum.id)} timeout='auto' unmountOnExit>
                          <KtwelveCurriculumsFormContainer
                            onSave={async () => {
                              close_editing_form();
                              await init();
                            }}
                            ktwelve_curriculum_id={ktwelve_curriculum.id}
                            all_ktwelve_curriculums={ktwelve_curriculums}
                            close_form={close_editing_form}
                            curriculum={curriculum}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 2}>
                        <Collapse in={expandedKtwelveSubjects.includes(~~ktwelve_curriculum.id)} timeout='auto' unmountOnExit>
                          <KtwelveSubjectTable ktwelve_curriculum={ktwelve_curriculum} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default KtwelveStep;
