import React from 'react'
import { Spinner } from './styles/Loading.style'

const Loading = () => {
  return (
    <div style={{ display: 'flex', height: 'fit-content', background: 'inherit', minHeight: '10rem', flexDirection: 'column', alignItems: 'center', fontSize: '18px', width: '100%' }}>
      <Spinner />
      <span>Carregando</span>
    </div>
  )
}

export default Loading
