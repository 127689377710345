import { createPromiseAction } from "redux-saga-promise-actions"
import { JsonFormat, JsonResponseFormat, WithRequestParams } from "../../utils/constants"
import { AxiosResponse } from "axios"
import { KtwelveSubjectJson } from "../ktwelve_subjects"
import { CompositionJson } from "../compositions"
import { CompositionPeriodJson } from "../composition_periods"
import { FormulaJson } from "../formulas"
import { ExamPlacementJson } from "../exam_placements"

export interface KtwelveCurriculumAttrs {
  company_id: number
  company_name: string
  composition_id: number
  composition_name: string
  curriculum_id: number
  curriculum_name: string
  interval_length: string
  ktwelve_degree_id: number
  ktwelve_degree_name: string
  year: number
  multiple_rooms: boolean
  label: string
}

export type KtwelveCurriculumJson = JsonFormat<'ktwelve_curriculums', KtwelveCurriculumAttrs>
export type FetchKtwelveCurriculumsResponse = JsonResponseFormat<KtwelveCurriculumJson[], (KtwelveSubjectJson|CompositionJson|CompositionPeriodJson|FormulaJson|ExamPlacementJson)[]>
export type FetchKtwelveCurriculumResponse = JsonResponseFormat<KtwelveCurriculumJson, (KtwelveSubjectJson|CompositionJson|CompositionPeriodJson|FormulaJson|ExamPlacementJson)[]>
export type FetchKtwelveCurriculumsPayload = WithRequestParams

export interface FetchKtwelveCurriculumPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateKtwelveCurriculumPayload {
  data: object
}

export interface UpdateKtwelveCurriculumPayload extends CreateKtwelveCurriculumPayload {
  id: string
}

export interface DeleteKtwelveCurriculumPayload {
  id: string
}


export const FETCH_KTWELVE_CURRICULUMS = createPromiseAction('formulas: FETCH_KTWELVE_CURRICULUMS')<FetchKtwelveCurriculumsPayload, FetchKtwelveCurriculumsResponse, any>()
export const FETCH_KTWELVE_CURRICULUM  = createPromiseAction('formulas: FETCH_KTWELVE_CURRICULUM')<FetchKtwelveCurriculumPayload, FetchKtwelveCurriculumResponse, any>()
export const CREATE_KTWELVE_CURRICULUM = createPromiseAction('formulas: CREATE_KTWELVE_CURRICULUM')<CreateKtwelveCurriculumPayload, FetchKtwelveCurriculumResponse, any>()
export const UPDATE_KTWELVE_CURRICULUM = createPromiseAction('formulas: UPDATE_KTWELVE_CURRICULUM')<UpdateKtwelveCurriculumPayload, FetchKtwelveCurriculumResponse, any>()
export const DELETE_KTWELVE_CURRICULUM = createPromiseAction('formulas: DELETE_KTWELVE_CURRICULUm')<DeleteKtwelveCurriculumPayload, AxiosResponse, any>()
