import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_SUBJECT_PERIOD_REGISTRATIONS,
  FetchSubjectPeriodRegistrationsPayload,
  FetchSubjectPeriodRegistrationPayload,
  FETCH_SUBJECT_PERIOD_REGISTRATION,
  CREATE_SUBJECT_PERIOD_REGISTRATION,
  CreateSubjectPeriodRegistrationPayload,
  UpdateSubjectPeriodRegistrationPayload,
  UPDATE_SUBJECT_PERIOD_REGISTRATION,
  DeleteSubjectPeriodRegistrationPayload,
  DELETE_SUBJECT_PERIOD_REGISTRATION,
  CALCULATE_SUBJECT_PERIOD_REGISTRATION_FREQUENCY,
  CALCULATE_SUBJECT_PERIOD_REGISTRATION_SCORE
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchSubjectPeriodRegistrations (action: DefaultAction<FetchSubjectPeriodRegistrationsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchSubjectPeriodRegistrations, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_SUBJECT_PERIOD_REGISTRATIONS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_SUBJECT_PERIOD_REGISTRATIONS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchSubjectPeriodRegistration (action: DefaultAction<FetchSubjectPeriodRegistrationPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchSubjectPeriodRegistration, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_SUBJECT_PERIOD_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_SUBJECT_PERIOD_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * calculateSubjectPeriodRegistrationFrequency (action: DefaultAction<FetchSubjectPeriodRegistrationPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.calculateFetchSubjectPeriodRegistrationFrequency, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CALCULATE_SUBJECT_PERIOD_REGISTRATION_FREQUENCY.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CALCULATE_SUBJECT_PERIOD_REGISTRATION_FREQUENCY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * calculateSubjectPeriodRegistrationScore (action: DefaultAction<FetchSubjectPeriodRegistrationPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.calculateFetchSubjectPeriodRegistrationScore, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CALCULATE_SUBJECT_PERIOD_REGISTRATION_SCORE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CALCULATE_SUBJECT_PERIOD_REGISTRATION_SCORE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createSubjectPeriodRegistration (action: DefaultAction<CreateSubjectPeriodRegistrationPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'subject_period_registrations',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createSubjectPeriodRegistration, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_SUBJECT_PERIOD_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_SUBJECT_PERIOD_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateSubjectPeriodRegistration (action: DefaultAction<UpdateSubjectPeriodRegistrationPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = data instanceof FormData ? data : {
      data: {
        type: 'subject_period_registrations',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateSubjectPeriodRegistration, { params, id})
    yield put(UPDATE_SUBJECT_PERIOD_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_SUBJECT_PERIOD_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteSubjectPeriodRegistration (action: DefaultAction<DeleteSubjectPeriodRegistrationPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteSubjectPeriodRegistration, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_SUBJECT_PERIOD_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_SUBJECT_PERIOD_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchSubjectPeriodRegistrationsSagas () {
  yield takeLatest(FETCH_SUBJECT_PERIOD_REGISTRATIONS.request, fetchSubjectPeriodRegistrations)
  yield takeLatest(FETCH_SUBJECT_PERIOD_REGISTRATION.request, fetchSubjectPeriodRegistration)
  yield takeLatest(CREATE_SUBJECT_PERIOD_REGISTRATION.request, createSubjectPeriodRegistration)
  yield takeLatest(UPDATE_SUBJECT_PERIOD_REGISTRATION.request, updateSubjectPeriodRegistration)
  yield takeLatest(DELETE_SUBJECT_PERIOD_REGISTRATION.request, deleteSubjectPeriodRegistration)
  yield takeLatest(CALCULATE_SUBJECT_PERIOD_REGISTRATION_FREQUENCY.request, calculateSubjectPeriodRegistrationFrequency)
  yield takeLatest(CALCULATE_SUBJECT_PERIOD_REGISTRATION_SCORE.request, calculateSubjectPeriodRegistrationScore)
}

export default function * subjectPeriodRegistrationsSagas () {
  yield all([watchSubjectPeriodRegistrationsSagas()])
}
