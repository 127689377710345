/* eslint-disable camelcase */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { flatten, includes, isEmpty, isNil, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { error, success } from 'react-notification-system-redux';

import { Theme } from '@mui/system';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { colors, Paths, FormFileType, Role, CompanyAttributes, Company } from '../../utils/constants';
import { evaluate_permissions, nameToInitials } from '../../utils/functions';

import { MenuItemModal } from '../modal/Modal';
import InputComponent from '../input/form/input';
import { IMPORT_COMPANY_DATA, UPDATE_COMPANY } from '../../store/companies';
import { RootState } from '../../store/configureStore';
import UploadComponent from '../input/form/upload';
import CloudIcon from '../icon/CloudIcon';
import TrashIcon from '../icon/TrashIcon';
import { UPDATE_STORED_COMPANIES } from '../../store/accounts';
import ExportCompanyDataOptions from '../shared/ExportCompanyData';

const colorSamples = ['#5FBFED', '#FACBBF'];

const useStyles = makeStyles(
  (
    theme: Theme,
  ): {
    view: any;
    title: any;
    hubs: any;
    hubWrapper: any;
    accordion: any;
    accordionSummary: any;
    accordionDetailsGrid: any;
    accordionDetailsGridItem: any;
    newHub: any;
    fund: any;
  } => {
    return {
      view: {
        width: 'inherit',
        height: 'fit-content',
        display: 'flex',
        flexGrow: '1',
        padding: '2rem 6rem',
        flexDirection: 'column',
        position: 'relative',
        '& .MuiBackdrop-root': {
          position: 'inherit',
        },
        '& .MuiPaper-root': {
          background: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        },
        [theme.breakpoints?.down('md')]: {
          padding: '2rem 1rem',
        },
      },
      title: {
        alignSelf: 'flex-start',
        fontSize: '2rem',
        marginBottom: '1rem',
      },
      hubs: {
        width: '100%',
        background: colors.lightBlue,
        borderRadius: '16px',
        '& .createGroup': {
          cursor: 'pointer',
          borderRadius: '32px',
          background: colors.grayBlue,
          width: 'fit-content',
          margin: 'auto',
          padding: '1rem',
          fontSize: '1.5rem',
          border: 'none',
          '&:hover': {
            transform: 'translateY(-0.1rem)',
            'box-shadow': '1px 6px 5px 0px rgba(0,0,0,0.75)',
          },
        },
      },
      fund: {
        cursor: 'pointer',
        '&:hover': {
          color: colors.darkBlue,
        },
      },
      hubWrapper: {
        position: 'relative',
        padding: '1rem',
        '& > svg': {
          position: 'absolute',
          right: '1rem',
          top: '0.5rem',
          fontSize: '2rem',
          color: colors.darkGrayBlue,
        },
        '& > div.groupHeader': {
          padding: '2rem 6rem',
          [theme.breakpoints?.down('lg')]: {
            padding: '2rem 1rem',
          },
        },
        '& > div > .MuiAccordion-root': {
          '&::before': {
            backgroundColor: 'inherit',
          },
        },
      },
      accordion: {
        background: 'inherit',
        'box-shadow': 'none',
        'border-bottom': `1px solid ${colors.grayBlue}`,
      },
      accordionSummary: {
        '& .MuiAccordionSummary-content': {
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 'inherit',
        },
        '& div': {
          display: 'flex',
          alignItems: 'center',
        },
        '& svg': {
          color: colors.darkGrayBlue,
        },
      },
      accordionDetailsGrid: {
        display: 'grid',
        gridTemplateColumns: '30% 30% 30%',
        justifyContent: 'space-around',
        padding: '2rem 0',
        rowGap: '1rem',
        width: '100%',
      },
      accordionDetailsGridItem: {
        display: 'grid',
        justifyContent: 'center',
        justifyItems: 'center',
        cursor: 'default',
        '& > div.logo,button.addSchool': {
          background: 'inherit',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          fontSize: '5rem',
          width: '10rem',
          height: '10rem',
          marginBottom: '2rem',
        },
        '& .addSchool': {
          border: `1px dashed ${colors.grayBlue}`,
          cursor: 'pointer',
          '&:hover': {
            transform: 'translateY(-0.1rem)',
            'box-shadow': '1px 6px 5px 0px rgba(0,0,0,0.75)',
          },
        },
        '& svg': {
          color: colors.darkGrayBlue,
        },
      },
      newHub: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        border: `1px dashed ${colors.grayBlue}`,
        justifyContent: 'center',
        background: 'inherit',
        borderRadius: '16px',
        fontSize: '2rem',
        padding: '2rem 0',
        marginTop: '2rem',
        '&:hover': {
          transform: 'translateY(-0.1rem)',
          'box-shadow': '1px 6px 5px 0px rgba(0,0,0,0.75)',
        },
      },
    };
  },
);

const ImportCompanyDataMenuOptions = ({
  onClose,
  companyName,
  companyId,
  role,
  current_logged_in_company,
  companyKind
}: {
  companyName: string;
  role: Role;
  companyId: string | number;
  onClose: () => void;
  current_logged_in_company: CompanyAttributes
  companyKind: Company
}) => {
  if (!([Role.SUPERUSER, Role.PEDAGOGICAL_ADMIN].includes(role) && [Company.FUND, Company.GROUP, Company.HUB].includes(current_logged_in_company.kind as Company)) || companyKind !== Company.SCHOOL) {
    return null;
  }
  const dispatch = useDispatch();
  const [url, setUrl] = React.useState('');
  const importCompanyDataMethod = async (
    { handleClose, setLoading }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; handleClose: () => void },
    id: string,
  ) => {
    try {
      setLoading(true);
      dispatch(
        IMPORT_COMPANY_DATA.request({
          id,
          data: {
            file_url: url,
          },
        }),
      );
      dispatch(
        success({
          message: 'Dados da planilha enviados',
        }),
      );
      setUrl('');
      handleClose();
      onClose();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        error({
          message: 'Erro na importação de dados de planilha',
        }),
      );
    }
  };

  return (
    <MenuItemModal
      key={companyId}
      label='Importar planilha'
      title={`Importar dados ${role === Role.PEDAGOGICAL_ADMIN ? "pedagógicos" : ""} de planilha para ${companyName}`}
      message='Insira o link de exportação abaixo'
      disableConfirm={url === '' || isNil(url)}
      onConfirm={(params) => importCompanyDataMethod(params, String(companyId))}
    >
      <InputComponent
        placeholder={`Link de exportação`}
        small
        input={{
          value: url,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setUrl(e.target.value);
          },
        }}
      />
    </MenuItemModal>
  );
};

const SaveDashboardOption = (props: {
  companyId: string;
  companyName: string;
  isSuperuser: boolean;
  onClose: () => void;
}) => {
  const { companyId, companyName, isSuperuser, onClose } = props;
  if (!isSuperuser) {
    return null;
  }
  const dispatch = useDispatch();
  const {
    account: { companies },
  } = useSelector((state: RootState) => state);
  const [file, setFile] = React.useState<FormFileType | null>();
  const saveDashboard = React.useCallback(
    async ({
      handleClose,
      setLoading,
    }: {
      setLoading: React.Dispatch<React.SetStateAction<boolean>>;
      handleClose: () => void;
    }) => {
      const formData = new FormData();
      formData.append('data[type]', 'companies');
      formData.append('data[id]', companyId);
      file && formData.append('data[attributes][dashboard]', file.file as Blob);
      try {
        setLoading(true);
        const company = await dispatch(
          UPDATE_COMPANY.request({
            id: companyId,
            data: formData,
          }),
        );
        const {
          data: {
            data: { attributes },
          },
        } = company;
        const adjustedCompanies = companies.map((item: any) => {
          if (item.id === companyId) {
            return { ...item, ...attributes };
          }
          return item;
        });
        dispatch(UPDATE_STORED_COMPANIES({ companies: adjustedCompanies }));
        setLoading(false);
        setFile(null);
        handleClose();
        onClose();
        dispatch(
          success({
            message: 'Dashboard salvo com sucesso',
          }),
        );
      } catch (err) {
        setLoading(false);
        handleClose();
        onClose();
        dispatch(
          error({
            message: 'Erro ao salvar dashboard',
          }),
        );
      }
    },
    [file, companyId, isSuperuser, onClose],
  );
  return (
    <MenuItemModal
      key={companyId}
      label='Salvar dashboard'
      title={`Salvar dashboard para a empresa ${companyName}`}
      message='Faça o upload do dashboard abaixo'
      onConfirm={(params) => saveDashboard(params)}
      confirmButtonText='Salvar'
    >
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: 'transparent',
            border: `1px dashed ${colors.grayBlue} `,
            padding: '1rem 1.5rem',
            position: 'relative',
          }}
        >
          <div
            style={{
              background: colors.grayBlue,
              borderRadius: '50%',
              width: '3rem',
              height: '3rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloudIcon />
          </div>
          <span style={{ fontSize: '0.75rem' }}>
            <strong>Solte o arquivo aqui</strong> ou navegue por seu <strong>computador</strong>
          </span>
          <span style={{ color: colors.darkGrayBlue, fontSize: '0.75rem' }}>
            Suporte Arquivos: Jpg, Png, Jpeg Dimensões mínimas: 170x135px
          </span>
          <UploadComponent
            input={{
              value: file,
              name: 'dashboard',
              onChange: (e: FormFileType) => {
                setFile(e);
              },
            }}
          />
        </div>
        {file && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '1rem',
            }}
          >
            <span style={{ textDecoration: 'underline' }}>
              {file?.name} - {file?.size}
            </span>
            <IconButton
              style={{
                backgroundColor: colors.lightRed,
              }}
              onClick={() => setFile(null)}
            >
              <TrashIcon style={{ height: '0.75rem', width: '0.75rem', color: 'white' }} />
            </IconButton>
          </div>
        )}
      </div>
    </MenuItemModal>
  );
};

const ActionsMenu = ({
  open,
  onClose,
  company,
  anchor,
  childrenCompanies,
}: {
  open: boolean;
  onClose: (event?: any, reason?: string) => void;
  company: CompanyAttributes;
  anchor: null | HTMLElement;
  childrenCompanies?: CompanyAttributes[];
}) => {
  const history = useHistory();
  const {
    auth: { profile, company: current_logged_in_company_id },
    account: { companies }
  } = useSelector((state: RootState) => state);
  const current_logged_in_company = companies.find((item: CompanyAttributes) => item.id === current_logged_in_company_id) as CompanyAttributes
  const isSchool = company.kind === 'school';
  const navigateTo = (path: Paths) => {
    history.push(path, {
      company_id: company.id,
      company_kind: company.kind,
      company_name: company.name,
    });
  };
  const navigateToCompanyForm = () => {
    navigateTo(Paths.COMPANY_FORM);
  };
  const navigateToDashboardProfilesForm = () => {
    navigateTo(Paths.PROFILE_DASHBOARDS_FORM);
  };

  const options = map(childrenCompanies, (company) => ({ value: company.id, label: company.name }));
  const isSuperUser = profile?.role === 'superuser';
  const canExportReport = evaluate_permissions.is_above_school_director(profile.role);
  const message = isEmpty(options)
    ? 'Clique em confirmar para exportar a planilha'
    : 'Escolha as escolas que deseja incluir no relatório';
  return (
    <Menu anchorEl={anchor} open={open} onClose={(event, reason) => onClose(event, reason)}>
      {isSuperUser && (
        <MenuItem onClick={navigateToCompanyForm}>
          <span>Editar</span>
        </MenuItem>
      )}
      <ImportCompanyDataMenuOptions
        onClose={onClose}
        companyKind={company.kind}
        companyName={company.name}
        companyId={company.id}
        current_logged_in_company={current_logged_in_company}
        role={profile.role}
      />
      {canExportReport && (
        <ExportCompanyDataOptions
          type='menu'
          message={message}
          isSchool={isSchool}
          allowedRole={canExportReport}
          companyId={company.id}
          companyName={company.name}
          companyKind={company.kind}
          options={options}
          onClose={onClose}
          is_invoices_view
          is_registrations_view
        />
      )}
      <SaveDashboardOption
        companyId={company.id as string}
        companyName={company.name}
        isSuperuser={isSuperUser}
        onClose={onClose}
      />
      {isSuperUser && (
        <MenuItem onClick={navigateToDashboardProfilesForm}>
          <span>Dashboards de perfil</span>
        </MenuItem>
      )}
    </Menu>
  );
};

const CompanyInfo = ({
  schools,
  parentId,
  currentCo,
  handleClickCompanyActions,
  anchorEl,
  handleClose,
}: {
  schools: CompanyAttributes[];
  parentId: string | number;
  currentCo: string | number | null;
  handleClickCompanyActions: (event: React.MouseEvent<HTMLElement>, id: string | number) => void;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [loadedImages, setLoadedImages] = React.useState<(string | number)[]>([]);
  return (
    <div className={classes.accordionDetailsGrid}>
      {schools.map((school: CompanyAttributes) => {
        return (
          <div className={classes.accordionDetailsGridItem} key={school.name}>
            <IconButton
              style={{
                marginLeft: 'auto',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                handleClickCompanyActions(e, school.id);
              }}
            >
              <MoreHorizIcon />
            </IconButton>
            <ActionsMenu
              open={Boolean(anchorEl) && currentCo === school.id}
              onClose={handleClose}
              company={school}
              anchor={anchorEl}
            />
            <div
              className='logo'
              style={{
                background: loadedImages.includes(school.id)
                  ? 'transparent'
                  : colorSamples[Math.floor(Math.random() * colorSamples.length)],
              }}
            >
              {school.logo_url ? (
                <img
                  src={school.logo_url}
                  onLoad={() => setLoadedImages((loaded) => loaded.concat(school.id))}
                  style={{
                    width: '100%',
                    height: '100%',
                    display: !loadedImages.includes(school.id) ? 'none' : 'initial',
                  }}
                />
              ) : (
                <span>{nameToInitials(school.name)}</span>
              )}
            </div>
            <span>{school.name}</span>
          </div>
        );
      })}
      <div
        data-testid='add-school-button'
        className={`${classes.accordionDetailsGridItem}`}
        onClick={() => {
          history.push(`${Paths.COMPANY_FORM}?kind=school`, {
            parent_id: parentId,
          });
        }}
      >
        <button className='addSchool'>
          <span>+</span>
        </button>
        <span>Adicionar nova escola</span>
      </div>
    </div>
  );
};

const HomeView = ({
  profile,
  hubs,
  groups,
  schools,
  company,
  title,
  currentCompany,
  publishers,
}: {
  profile: Role;
  title: string;
  company: string;
  hubs: CompanyAttributes[];
  groups: CompanyAttributes[];
  schools: CompanyAttributes[];
  currentCompany: CompanyAttributes | null;
  publishers: CompanyAttributes[];
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentCo, setCurrentCo] = React.useState<string | number | null>(null);
  const [expandedCompanies, setExpandedCompanies] = React.useState<(string | number)[]>([]);

  const handleClickCompanyActions = React.useCallback((event: React.MouseEvent<HTMLElement>, id: string | number) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setCurrentCo(id);
  }, []);
  const handleClose = React.useCallback((e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    setAnchorEl(null);
    setCurrentCo(null);
  }, []);
  const handleExpand = React.useCallback(
    (id: string | number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      event.stopPropagation();
      if (isNil(anchorEl) && isNil(currentCo)) {
        if (isExpanded) {
          setExpandedCompanies((current) => current.concat(id));
        } else {
          setExpandedCompanies((current) => current.filter((item) => item !== id));
        }
      }
    },
    [anchorEl, currentCo],
  );

  return (
    <div className={classes.view}>
      <div style={{
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${colors.darkBlue}`
      }}>
        <span className={`${classes.title} title`}>{title}</span>
        {currentCompany && !isEmpty(schools) && (
          <>
            <IconButton
              style={{
                fontSize: '2rem',
                color: colors.darkGrayBlue,
              }}
              onClick={(e) => handleClickCompanyActions(e, currentCompany.id)}
            >
              <MoreHorizIcon />
            </IconButton>
            <ActionsMenu
              open={Boolean(anchorEl) && currentCo === currentCompany.id}
              onClose={handleClose}
              company={currentCompany}
              anchor={anchorEl}
              childrenCompanies={schools}
            />
          </>
        )}
      </div>
      { [Role.SUPERUSER, Role.FUND_ADMIN].includes(profile) ? (
        <>
          <div className={classes.hubs}>
            {hubs.map((hub) => {
              const hubgroups = groups.filter((group) => {
                return group.parent_id === ~~hub.id;
              });
              const hubSchools = schools.concat(publishers).filter((school) => {
                return school.parent_id === ~~hub.id;
              });
              const hubGroupsSchools = flatten(
                hubgroups
                  .map((group) => {
                    const groupSchools = schools.filter((school) => {
                      return school.parent_id === ~~group.id;
                    });
                    return groupSchools;
                  })
                  .concat(hubSchools),
              );
              return (
                <div key={hub.id} className={classes.hubWrapper}>
                  <IconButton
                    style={{
                      position: 'absolute',
                      right: '1rem',
                      top: '0.5rem',
                      fontSize: '2rem',
                      color: colors.darkGrayBlue,
                    }}
                    onClick={(e) => handleClickCompanyActions(e, hub.id)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <ActionsMenu
                    open={Boolean(anchorEl) && currentCo === hub.id}
                    childrenCompanies={hubGroupsSchools}
                    onClose={handleClose}
                    company={hub}
                    anchor={anchorEl}
                  />
                  <span className='subtitle-one'>{hub.name}</span>
                  {hubgroups.map((group) => {
                    const groupSchools = schools.filter((school) => {
                      return school.parent_id === ~~group.id;
                    });
                    return (
                      <div className='groupHeader' key={group.id}>
                        <Accordion
                          expanded={includes(expandedCompanies, group.id)}
                          onChange={handleExpand(group.id)}
                          className={classes.accordion}
                        >
                          <AccordionSummary className={classes.accordionSummary}>
                            <div>
                              <IconButton
                                style={{
                                  position: 'absolute',
                                  right: '1rem',
                                  top: '0.5rem',
                                  fontSize: '2rem',
                                  color: colors.darkGrayBlue,
                                }}
                                onClick={(e) => handleClickCompanyActions(e, group.id)}
                              >
                                <MoreHorizIcon />
                              </IconButton>
                              <ActionsMenu
                                open={Boolean(anchorEl) && currentCo === group.id}
                                onClose={handleClose}
                                company={group}
                                anchor={anchorEl}
                                childrenCompanies={groupSchools}
                              />
                              <span>{group.name}</span>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CompanyInfo
                              schools={groupSchools}
                              parentId={group.id}
                              anchorEl={anchorEl}
                              currentCo={currentCo}
                              handleClickCompanyActions={handleClickCompanyActions}
                              handleClose={handleClose}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    );
                  })}
                  <CompanyInfo
                    schools={hubSchools}
                    parentId={hub.id}
                    anchorEl={anchorEl}
                    currentCo={currentCo}
                    handleClickCompanyActions={handleClickCompanyActions}
                    handleClose={handleClose}
                  />
                  <button
                    onClick={() =>
                      history.push(`${Paths.COMPANY_FORM}?kind=group`, {
                        parent_id: hub.id,
                      })
                    }
                    className='createGroup'
                  >
                    <span> + Criar novo grupo neste hub </span>
                  </button>
                  <hr />
                </div>
              );
            })}
          </div>
          <button
            onClick={() =>
              history.push(`${Paths.COMPANY_FORM}?kind=hub`, {
                parent_id: company,
              })
            }
            className={classes.newHub}
          >
            <span>+ Criar novo hub</span>
          </button>
          <hr />
          <span className='subtitle-one'>Grupos do fundo</span>
          <div className={classes.hubWrapper}>
            {groups
              .filter((group) => group.parent_id.toString() === currentCompany?.id)
              .map((group) => {
                const groupSchools = schools.filter((school) => {
                  return school.parent_id === ~~group.id;
                });
                return (
                  <div key={group.id}>
                    <Accordion
                      expanded={includes(expandedCompanies, group.id)}
                      onChange={handleExpand(group.id)}
                      className={classes.accordion}
                    >
                      <AccordionSummary className={classes.accordionSummary}>
                        <div>
                          <IconButton
                            style={{
                              position: 'absolute',
                              right: '1rem',
                              top: '0.5rem',
                              fontSize: '2rem',
                              color: colors.darkGrayBlue,
                            }}
                            onClick={(e) => handleClickCompanyActions(e, group.id)}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                          <ActionsMenu
                            open={Boolean(anchorEl) && currentCo === group.id}
                            onClose={handleClose}
                            company={group}
                            anchor={anchorEl}
                            childrenCompanies={groupSchools}
                          />
                          <span>{group.name}</span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CompanyInfo
                          schools={groupSchools}
                          parentId={group.id}
                          anchorEl={anchorEl}
                          currentCo={currentCo}
                          handleClickCompanyActions={handleClickCompanyActions}
                          handleClose={handleClose}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
          </div>
          <button
            onClick={() =>
              history.push(`${Paths.COMPANY_FORM}?kind=group`, {
                parent_id: ~~company,
              })
            }
            className={classes.newHub}
          >
            <span> + Criar novo grupo neste fundo </span>
          </button>
        </>
      ) : [Role.HUB_ADMIN, Role.PEDAGOGICAL_ADMIN].includes(profile) ? (
        <div className={classes.hubs}>
          <div className={classes.hubWrapper}>
            {groups.map((group) => {
              const groupSchools = schools.filter((school) => {
                return school.parent_id === ~~group.id;
              });
              return (
                <div className='groupHeader' key={group.id}>
                  <Accordion
                    expanded={includes(expandedCompanies, group.id)}
                    onChange={handleExpand(group.id)}
                    className={classes.accordion}
                  >
                    <AccordionSummary className={classes.accordionSummary}>
                      <div>
                        <IconButton
                          style={{
                            position: 'absolute',
                            right: '1rem',
                            top: '0.5rem',
                            fontSize: '2rem',
                            color: colors.darkGrayBlue,
                          }}
                          onClick={(e) => handleClickCompanyActions(e, group.id)}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <ActionsMenu
                          open={Boolean(anchorEl) && currentCo === group.id}
                          onClose={handleClose}
                          company={group}
                          anchor={anchorEl}
                          childrenCompanies={groupSchools}
                        />
                        <span>{group.name}</span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CompanyInfo
                        schools={groupSchools}
                        parentId={group.id}
                        anchorEl={anchorEl}
                        currentCo={currentCo}
                        handleClickCompanyActions={handleClickCompanyActions}
                        handleClose={handleClose}
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
            <button
              onClick={() =>
                history.push(`${Paths.COMPANY_FORM}?kind=group`, {
                  parent_id: ~~company,
                })
              }
              className='createGroup'
            >
              <span> + Criar novo grupo neste hub </span>
            </button>
          </div>
          <CompanyInfo
            schools={schools.filter((school) => {
              return school.parent_id === ~~company;
            })}
            parentId={company}
            anchorEl={anchorEl}
            currentCo={currentCo}
            handleClickCompanyActions={handleClickCompanyActions}
            handleClose={handleClose}
          />
        </div>
      ) : [Role.GROUP_ADMIN, Role.PEDAGOGICAL_ADMIN].includes(profile) ? (
        <div className={classes.hubs}>
          <CompanyInfo
            schools={schools.filter((school) => {
              return school.parent_id === ~~company;
            })}
            parentId={company}
            anchorEl={anchorEl}
            currentCo={currentCo}
            handleClickCompanyActions={handleClickCompanyActions}
            handleClose={handleClose}
          />
        </div>
      ) : null}
    </div>
  );
};

export default HomeView;
