import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const subjectPeriodRegistrationsEndpoint = '/subject_period_registrations'
const multipartInstance = http
multipartInstance.defaults.headers.common['Content-Type'] =
    'multipart/form-data; boundary=----------XnJLe9ZIbbGUYtzPQJ16u1'


export const fetchSubjectPeriodRegistrations = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${subjectPeriodRegistrationsEndpoint}?${queryParams}`)
}

export const fetchSubjectPeriodRegistration = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${subjectPeriodRegistrationsEndpoint}/${id}?${queryParams}`)
}

export const calculateFetchSubjectPeriodRegistrationFrequency = ({ id, params }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${subjectPeriodRegistrationsEndpoint}/${id}/calculate_frequency?${queryParams}`)
}

export const calculateFetchSubjectPeriodRegistrationScore = ({ id, params }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${subjectPeriodRegistrationsEndpoint}/${id}/calculate_score?${queryParams}`)
}

export const createSubjectPeriodRegistration = (data: object) => {
  return http.post(`${subjectPeriodRegistrationsEndpoint}`, data)
}

export const updateSubjectPeriodRegistration = ({ params, id }: { params: FormData | object, id: string|number }) => {
  const instance = params instanceof FormData ? multipartInstance : http

  return instance.put(`${subjectPeriodRegistrationsEndpoint}/${id}`, params)
}

export const deleteSubjectPeriodRegistration = ({ id }: { id: string | number } ) => {
  return http.delete(`${subjectPeriodRegistrationsEndpoint}/${id}`)
}
