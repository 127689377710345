import React from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { css } from '@emotion/react';
import { colors } from '../../utils/constants'
import { IconButton, Tooltip } from '@mui/material';

const buttonCss = css`
  display: flex;
  align-items: center;
  height: fit-content;
  font-size: 1.25rem;
  cursor: pointer;
  margin-bottom: 1rem;
  border: none;
  gap: 1rem;
  width: fit-content;
  background: inherit;
`

const iconCss = css `
  font-size: 3rem;
  color: ${colors.darkBlue};
`


const AddButton = (props: { onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void, tooltip?: string, disabled?: boolean  }) => {
  const {onClick, tooltip, disabled = false} = props
  const Wrapper = tooltip ? (props:{children: React.ReactElement}) => {
    const { children } = props
    return <Tooltip title={tooltip || ''}>{children}</Tooltip>
  } : React.Fragment

  return (
    <Wrapper>
      <IconButton
        css={buttonCss}
        onClick={onClick}
        disabled={disabled}
      >
        <AddCircleIcon css={iconCss} />
      </IconButton>
    </Wrapper>
  )
}

export default AddButton