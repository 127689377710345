/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ExamPlaceableType,
  ExamPlacementAttributes,
  FormulaAttributes,
  LocationState,
} from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { CREATE_EXAM_PLACEMENT, FETCH_EXAM_PLACEMENT, UPDATE_EXAM_PLACEMENT } from '../store/exam_placements';
import ExamPlacementsForm from '../components/form/ExamPlacementsForm';


const ExamPlacementFormContainer = (props: {
  exam_placeable_id: number;
  exam_placeable_type: ExamPlaceableType;
  exam_placement_id?: string
  exam_placeable_name: string
  onSave: () => void;
  close_form?: () => void;
  latest_order?: number
  not_allowed_orders: string[]
}) => {
  const { exam_placement_id, exam_placeable_id, exam_placeable_type, onSave, close_form, latest_order, exam_placeable_name, not_allowed_orders } = props;
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [initialValues, setInitialValues] = React.useState<Partial<ExamPlacementAttributes> | null>(null);
  const [loaded, setLoaded] = React.useState(false);

  const isUpdating = !isNil(initialValues?.id);

  const loadExamPlacement = React.useCallback(async () => {
    if (exam_placement_id) {
      const response = await dispatch(
        FETCH_EXAM_PLACEMENT.request({
          id: exam_placement_id,
        }),
      );
      const {
        data: { data },
      } = response;
      const exam_placement = {
        id: data.id,
        ...data.attributes,
      };

      setInitialValues(exam_placement);
    } else {
      const order = latest_order ? latest_order + 1 : 1
      setInitialValues({
        exam_placeable_id,
        exam_placeable_type,
        order,
        name: [`Avaliação ${order}`, exam_placeable_name].join(' - '),
        mandatory: true
      });
    }
  }, [location]);

  const initExamPlacementForm = React.useCallback(async () => {
    await loadExamPlacement();
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: Partial<ExamPlacementAttributes>) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'exam_placeable_id',
            'exam_placeable_type',
          ]) as FormulaAttributes;
          await dispatch(
            UPDATE_EXAM_PLACEMENT.request({
              id: initialValues?.id as string,
              data: {
                ...rest,
              },
            }),
          );
        } else {
          await dispatch(
            CREATE_EXAM_PLACEMENT.request({
              data: {
                ...data,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Avaliação salva com sucesso.',
          }),
        );
        await onSave();
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar avaliação.',
          }),
        );
      }
    },
    [initialValues, isUpdating, history],
  );

  React.useEffect(() => {
    initExamPlacementForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return (
    <ExamPlacementsForm
      initialValues={initialValues}
      close_form={close_form}
      onSubmit={onSubmit}
      not_allowed_orders={not_allowed_orders}
    />
  );
};

export default ExamPlacementFormContainer;
