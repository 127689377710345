/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  colors,
  CompositionPeriodAttributes,
  CompositionPeriodKindEnum,
  FormulaAttributes,
  FormulableType,
} from '../../utils/constants';
import { css } from '@emotion/react';
import { Collapse } from '@mui/material';
import FormulasFormContainer from '../../containers/FormulasFormContainer';
import { useDispatch } from 'react-redux';
import { FETCH_COMPOSITION_PERIOD } from '../../store/composition_periods';
import { concat, filter, find, isEmpty, map, max, orderBy, uniq } from 'lodash';
import { DELETE_FORMULA, FETCH_FORMULA, FormulaJson } from '../../store/formulas';
import { error, warning } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import TooltipButton from '../shared/TooltipButton';
import PenIcon from '../icon/PenIcon';
import { FETCH_SUBJECT_PERIOD } from '../../store/subject_periods';
import { IconModal } from '../modal/Modal';
import TrashIcon from '../icon/TrashIcon';
import { OperationJson } from '../../store/operations';

const TableCss = css`
  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const FormulasTable = ({
  composition_period_id,
  composition_periods,
  subject_period_id,
  subject_period_ids,
  formulable_type,
  formulable_id,
  composition_period_kind,
  formulable_name,
}: {
  composition_period_id: string;
  composition_periods: CompositionPeriodAttributes[];
  composition_period_kind: CompositionPeriodKindEnum;
  subject_period_ids?: number[];
  subject_period_id?: string;
  formulable_type: FormulableType;
  formulable_id: string;
  formulable_name: string;
}) => {
  const [createFormula, setCreateFormula] = React.useState(false);
  const [editFormula, setEditFormula] = React.useState<number[]>([]);
  const [formulas, setFormulas] = React.useState<FormulaAttributes[]>([]);
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();

  const fetchFormulas = React.useCallback(async () => {
    try {
      let formulas_attributes = [] as FormulaAttributes[];
      const response = await dispatch(
        FETCH_COMPOSITION_PERIOD.request({
          id: composition_period_id,
          params: {
            filters: {
              include: 'formulas',
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      formulas_attributes = map(included.filter((incl) => incl.type === 'formulas') as FormulaJson[], (item) => ({
        id: item.id,
        ...item.attributes,
      }));
      if (formulable_type === FormulableType.SUBJECT_PERIOD && subject_period_id) {
        const response = await dispatch(
          FETCH_SUBJECT_PERIOD.request({
            id: subject_period_id,
            params: {
              filters: {
                include: 'formulas',
              },
            },
          }),
        );
        const {
          data: { included = [] },
        } = response;
        formulas_attributes = concat(formulas_attributes,map(included.filter((incl) => incl.type === 'formulas') as FormulaJson[], (item) => ({
          id: item.id,
          ...item.attributes,
        })));
      }
      setFormulas(formulas_attributes);
    } catch (erro) {
      dispatch(
        error({
          message: 'Erro ao carregar fórmulas',
        }),
      );
    }
  }, [composition_period_id, subject_period_id, formulable_type, formulable_id]);

  const init = async () => {
    setLoading(true);
    await fetchFormulas();
    setLoading(false);
  };

  const verifyIfCanDeleteFormula = async (props: { handleClose: () => void; id: string }) => {
    const { handleClose, id } = props;
    try {
      const response = await dispatch(
        FETCH_FORMULA.request({
          id,
          params: {
            filters: {
              include: ['operations'].join(','),
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      if (!isEmpty(included)) {
        const operations = uniq(
          map(
            filter(included, (item) => item.type === 'operations') as OperationJson[],
            (item) => item.attributes.formula_name,
          ),
        );
        dispatch(
          warning({
            message: `Não é possível excluir fórmula pois ela está sendo usada nas formulas: ${operations.join(',')}`,
          }),
        );
        handleClose();
      }
    } catch (err) {
      dispatch(
        error({
          message: `Erro ao verificar fórmula`,
        }),
      );
      handleClose();
    }
  };

  const deleteFormula = async (props: {
    id: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleClose: () => void;
  }) => {
    const { id, handleClose } = props;
    setLoading(true);
    try {
      await dispatch(
        DELETE_FORMULA.request({
          id,
        }),
      );
      setLoading(false);
      handleClose();
      await init();
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao remover fórmula',
        }),
      );
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <Table css={TableCss} size='small' aria-label='formulas'>
      <TableHead>
        <TableRow>
          <TableCell align='left'>Ordem</TableCell>
          <TableCell align='center'>Nome</TableCell>
          <TableCell align='center'>Origem da Fórmula</TableCell>
          <TableCell align='center'>Média de aprovação</TableCell>
          <TableCell align='right'>
            <button
              disabled={createFormula || editFormula.length > 0}
              onClick={() => setCreateFormula(!createFormula)}
              className='green small'
            >
              <span> Criar Fórmula </span>
            </button>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={createFormula} timeout='auto' unmountOnExit>
              <FormulasFormContainer
                not_allowed_steps={formulas.map(item => item.step.toString())}
                formulable_name={formulable_name}
                composition_period_id={composition_period_id}
                latest_step={max(formulas.map((item) => item.step)) as number}
                formulable_id={~~formulable_id}
                composition_periods={composition_periods}
                subject_period_ids={subject_period_ids}
                subject_period_id={subject_period_id}
                composition_period_kind={composition_period_kind}
                formulable_type={formulable_type}
                onSave={async () => {
                  await init();
                  setCreateFormula(false);
                }}
                close_form={() => setCreateFormula(false)}
              />
            </Collapse>
          </TableCell>
        </TableRow>
        {orderBy(formulas, 'order', 'asc').map((formula) => {
          const toggle_form = () => {
            setEditFormula((current) => {
              if (current.includes(~~formula.id)) {
                return current.filter((item) => item !== ~~formula.id);
              } else {
                return current.concat(~~formula.id);
              }
            });
          };
          const composition_period = find(composition_periods, cp => cp.id === composition_period_id) as CompositionPeriodAttributes
          return (
            <React.Fragment key={formula.id}>
              <TableRow>
                <TableCell align='left'>{formula.step}</TableCell>
                <TableCell align='center'>{formula.name}</TableCell>
                <TableCell align='center'>{formula.formulable_type === FormulableType.COMPOSITION_PERIOD ? composition_period.name : formulable_name}</TableCell>
                <TableCell align='center'>{formula.threshold}</TableCell>
                <TableCell align='right'>
                  <TooltipButton
                    tooltipProps={{
                      title: editFormula.includes(~~formula.id) ? 'Cancelar edição' : 'Editar fórmula',
                      style: {
                        ...(formulable_type === FormulableType.SUBJECT_PERIOD &&
                        formula.formulable_type === FormulableType.COMPOSITION_PERIOD
                          ? { display: 'none' }
                          : {}),
                      }
                    }}
                    Icon={PenIcon}
                    iconProps={{ style: { color: colors.blue } }}
                    iconButtonProps={{
                      disabled: createFormula || (editFormula.length > 0 && !editFormula.includes(~~formula.id)),
                      onClick: toggle_form,
                    }}
                  />
                  <IconModal
                    icon={TrashIcon}
                    onConfirm={(props) => deleteFormula({ ...props, id: formula.id })}
                    tooltipText='Remover fórmula'
                    title='Remover fórmula'
                    message={`Deseja remover fórmula ${formula.name}?`}
                    onOpen={(args) =>
                      verifyIfCanDeleteFormula({
                        ...args,
                        id: formula.id,
                      })
                    }
                    tooltipProps={{
                      style: {
                        ...(formulable_type === FormulableType.SUBJECT_PERIOD &&
                        formula.formulable_type === FormulableType.COMPOSITION_PERIOD
                          ? { display: 'none' }
                          : {}),
                      },
                    }}
                    disabled={createFormula || editFormula.length > 0}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={editFormula.includes(~~formula.id)} timeout='auto' unmountOnExit>
                    <FormulasFormContainer
                      not_allowed_steps={formulas.map(item => item.step.toString()).filter(step => step !== formula.step.toString())}
                      formulable_name={formulable_name}
                      composition_period_id={composition_period_id}
                      formulable_id={~~formulable_id}
                      composition_periods={composition_periods}
                      subject_period_ids={subject_period_ids}
                      subject_period_id={subject_period_id}
                      composition_period_kind={composition_period_kind}
                      formulable_type={formulable_type}
                      onSave={async () => {
                        await init();
                        toggle_form();
                      }}
                      close_form={toggle_form}
                      formula_id={formula.id}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default FormulasTable;
