/* eslint-disable camelcase */
import React from 'react';

import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import { RiChatHistoryLine, RiChatHistoryFill, RiFileHistoryLine } from 'react-icons/ri';

import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import {
  colors,
  PaginationType,
  Paths,
  Role,
  defaultPagination,
  TeacherAttributes,
} from '../../../utils/constants';
import TablePaginationActions from '../../shared/TablePaginationActions';
import { useHistory } from 'react-router-dom';
import InputComponent from '../../input/form/input';
import SelectComponent from '../../input/form/select';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import TrashIcon from '../../icon/TrashIcon';
import { IconModal } from '../../modal/Modal';
import PenIcon from '../../icon/PenIcon';
import TooltipButton from '../../shared/TooltipButton';
import ExamsLog from '../../table/ExamsLogTable';
import StudentAbsenceLogTable from '../../table/StudentAbsenceLogTable';
import ClassPlanLogTable from '../../table/ClassPlanLogTable';
import ClassTimeDisplayTable from '../../table/ClassTimeDisplayTable';
import { CiViewTable } from 'react-icons/ci';
import { FETCH_TEACHER_SUBJECTS } from '../../../store/teacher_subjects';
import { isEmpty } from 'lodash';
import { error } from 'react-notification-system-redux';

const useStyles = makeStyles(
  (
    theme: Theme,
  ): {
    view: any;
    tableTitle: any;
    button: any;
    filters: any;
    table: any;
  } => ({
    view: {
      width: 'inherit',
      minHeight: '100%',
      height: 'fit-content',
      display: 'flex',
      padding: '2rem',
      flexDirection: 'column',
      flexGrow: '1',
      position: 'relative',
      [theme.breakpoints?.down('lg')]: {
        padding: '2rem 1rem',
      },
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
        overflow: 'hidden',
      },
    },
    tableTitle: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1rem',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      cursor: 'pointer',
      background: colors.green,
      padding: '1rem 4rem',
      border: 'none',
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      gap: '1rem',
      '& > span': {
        marginBottom: '14px',
      },
      '& .inputArea': {
        width: '10rem !important',
        paddingRight: '3rem',
      },
    },
    table: {
      background: colors.lightBlue,
      padding: '2rem',
      borderRadius: '4px',
      display: 'grid',
      '& .subtitle': {
        textAlign: 'start',
        marginBottom: '1rem',
      },
      '& .MuiTableContainer-root': {
        background: 'inherit',
        boxShadow: 'none',
        '& .MuiTableCell-root': {
          borderBottom: `1px solid ${colors.grayBlue}`,
        },

        '& th': {
          color: colors.darkGrayBlue,
        },
        '& td': {
          fontSize: '12px',
          color: colors.darkBlue,
          [theme.breakpoints?.down('lg')]: {
            fontSize: '0.75rem',
          },
          '& svg': {
            cursor: 'pointer',
            margin: '0 0.25rem',
          },
        },
        '& td.MuiTableCell-footer ': {
          borderBottom: 'none',
        },
      },
    },
  }),
);


const TeachersView = ({
  teachers,
  pagination,
  handleChangePageSize,
  handlePageChange,
  nameFilterValue,
  setNameFilterValue,
  fetchTeachersMethod,
  companyFilterValue,
  setCompanyFilterValue,
  deleteTeacher,
}: {
  teachers: TeacherAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fetchTeachersMethod: ({
    newPagination,
    company_filter,
  }: {
    newPagination?: PaginationType | undefined;
    company_filter?: string;
  }) => Promise<void>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
  deleteTeacher: (
    id: string,
    {
      setLoading,
      handleClose,
    }: {
      setLoading: React.Dispatch<React.SetStateAction<boolean>>;
      handleClose: () => void;
    },
  ) => Promise<void>;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch()
  const [expandedExamLog, setExpandedExamLog] = React.useState<number[]>([]);
  const [expandedPresenceLog, setExpandedPresenceLog] = React.useState<number[]>([]);
  const [expandedClassPlanLog, setExpandedClassPlanLog] = React.useState<number[]>([]);
  const [expandedClassTimes, setExpandedClassTimes] = React.useState<number[]>([]);


  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const verifyIfCanDeleteTeacher = React.useCallback(
    async (args: { handleClose: () => void; teacher_id: string }) => {
      const { teacher_id, handleClose } = args;
      try {
        const response = await dispatch(
          FETCH_TEACHER_SUBJECTS.request({
            params: {
              filters: {
                'q[teacher_id_eq]': teacher_id,
              }
            }
          })
        )
        const { data: { data } } = response
        if (!isEmpty(data)) {
          dispatch(
            error({
              message: 'Não é possível excluir o professor pois ele está ligado a uma disciplina em uma matriz curricular',
            }),
          );
          handleClose();
        }
      } catch (error) {
        handleClose();
      }
    },
    [],
  );

  const columns = [...(is_above_school_director ? ['Unidade'] : []), 'Nome'];

  return (
    <div className={classes.view}>
      <div className={classes.tableTitle}>
        <span className='title'>Professores</span>
        <button className='green small' onClick={() => history.push(Paths.TEACHERS_FORM)}>
          <span>Criar Professor</span>
        </button>
      </div>
      <div className={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div className={classes.filters}>
          {is_above_school_director && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchTeachersMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchTeachersMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => {
                  const align = index === 0 ? 'left' : 'center';
                  return (
                    <TableCell align={align} key={item}>
                      {item}
                    </TableCell>
                  );
                })}
                <TableCell align='right' />
              </TableRow>
            </TableHead>
            <TableBody>
              {teachers.map((teacher) => {
                return (
                  <>
                    <TableRow key={teacher.id}>
                      {is_above_school_director && <TableCell align='left'>{teacher.teacher_company_name}</TableCell>}
                      <TableCell align={`${is_above_school_director ? 'center' : 'left'}`}>
                        {teacher.teacher_name}
                      </TableCell>
                      <TableCell align='right'>
                        <TooltipButton
                          tooltipProps={{
                            title: expandedClassTimes.includes(~~teacher.id)
                              ? 'Ocultar horários do professor'
                              : 'Ver horários do professor',
                          }}
                          Icon={CiViewTable}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedClassTimes((current) => {
                                if (current.includes(~~teacher.id)) {
                                  return current.filter((item) => item !== ~~teacher.id);
                                } else {
                                  return current.concat(~~teacher.id);
                                }
                              }),
                          }}
                        />
                        <TooltipButton
                          tooltipProps={{
                            title: expandedExamLog.includes(~~teacher.id)
                              ? 'Ocultar log de alterações em planos de aula'
                              : 'Ver log de alterações em planos de aula',
                          }}
                          Icon={RiFileHistoryLine}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedClassPlanLog((current) => {
                                if (current.includes(~~teacher.id)) {
                                  return current.filter((item) => item !== ~~teacher.id);
                                } else {
                                  return current.concat(~~teacher.id);
                                }
                              }),
                          }}
                        />
                        <TooltipButton
                          tooltipProps={{
                            title: expandedExamLog.includes(~~teacher.id)
                              ? 'Ocultar log de alterações em avaliações'
                              : 'Ver log de alterações em avaliações',
                          }}
                          Icon={RiChatHistoryLine}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedExamLog((current) => {
                                if (current.includes(~~teacher.id)) {
                                  return current.filter((item) => item !== ~~teacher.id);
                                } else {
                                  return current.concat(~~teacher.id);
                                }
                              }),
                          }}
                        />
                        <TooltipButton
                          tooltipProps={{
                            title: expandedPresenceLog.includes(~~teacher.id)
                              ? 'Ocultar log de alterações em faltas'
                              : 'Ver log de alterações em faltas',
                          }}
                          Icon={RiChatHistoryFill}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedPresenceLog((current) => {
                                if (current.includes(~~teacher.id)) {
                                  return current.filter((item) => item !== ~~teacher.id);
                                } else {
                                  return current.concat(~~teacher.id);
                                }
                              }),
                          }}
                        />
                        <Tooltip title='Editar professor'>
                          <IconButton
                            onClick={() => {
                              history.push(Paths.TEACHERS_FORM, {
                                teacher_id: teacher.id,
                                company_id: teacher.teacher_company_id,
                              });
                            }}
                          >
                            <PenIcon />
                          </IconButton>
                        </Tooltip>
                        <IconModal
                          icon={TrashIcon}
                          title={'Excluir professor'}
                          tooltipText='Excluir professor'
                          onConfirm={(props) => deleteTeacher(teacher.id, props)}
                          message='Tem certeza que deseja remover o professor?'
                          iconProps={{ style: { color: 'red' } }}
                          onOpen={(args) => verifyIfCanDeleteTeacher({...args, teacher_id: teacher.id})}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={expandedClassPlanLog.includes(~~teacher.id)} timeout='auto' unmountOnExit>
                          <ClassPlanLogTable user_id={teacher.user_id.toString()} company_id={teacher.teacher_company_id.toString()} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={expandedExamLog.includes(~~teacher.id)} timeout='auto' unmountOnExit>
                          <ExamsLog user_id={teacher.user_id.toString()} company_id={teacher.teacher_company_id.toString()}/>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={expandedPresenceLog.includes(~~teacher.id)} timeout='auto' unmountOnExit>
                          <StudentAbsenceLogTable user_id={teacher.user_id.toString()} company_id={teacher.teacher_company_id.toString()} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedClassTimes.includes(~~teacher.id)} timeout='auto' unmountOnExit>
                      <ClassTimeDisplayTable teacher_id={teacher.id} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                  </>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default TeachersView;
