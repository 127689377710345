import React from 'react';
import Radio from '@mui/material/Radio';
import { css } from '@emotion/react';
import { colors } from '../../../utils/constants';
import { Tooltip } from '@mui/material';

const RadioCss = css`
  button {
    background: white;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
  }

  button.selected {
    background: ${colors.blue};
  }
`;

const ButtonRadioComponent = ({ input, meta, label, title, ...rest }: any) => {
  return (
    <div css={RadioCss}>
      <Tooltip title={title}>
        <Radio
          checkedIcon={<button className='selected'>{label}</button>}
          icon={<button>{label}</button>}
          data-testid={input.name}
          {...input}
          {...rest}
          checked={input.value === rest.value}
        />
      </Tooltip>
    </div>
  );
};

export default ButtonRadioComponent;
