import { IconButton, IconButtonProps, Tooltip, TooltipProps } from '@mui/material';
import React from 'react'

interface CustomIconButtonProps extends Partial<IconButtonProps> {
  href?: string
  target?: string
  rel?: string
}

const TooltipButton  = (props: {
  tooltipProps: Omit<TooltipProps, 'children'>,
  iconButtonProps?: CustomIconButtonProps
  iconProps?: object
  Icon: React.ElementType
  disable_display?: boolean
}) => {
  const {tooltipProps, iconButtonProps, iconProps = {}, Icon, disable_display} = props
  if(disable_display){
    return null
  }
  return (
    <Tooltip {...tooltipProps}>
      <IconButton {...iconButtonProps}>
        <Icon {...iconProps} />
      </IconButton>
    </Tooltip>
  )
}

export default TooltipButton