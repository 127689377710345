/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PiStudent, PiExam } from 'react-icons/pi';
import { CiViewTable } from 'react-icons/ci';
import { RiCalendarScheduleLine } from 'react-icons/ri';
import {
  colors,
  RoomAttributes,
  Role,
  PaginationType,
  defaultPagination,
  KtwelveSubjectAttributes,
  CompositionAttributes,
  CompositionPeriodAttributes,
  FormulableType,
  ExamPlaceableType,
  ExamPlacementAttributes,
  FormulaAttributes,
  SubjectPeriodRegistrationAttributes,
  ExamAttributes,
  RegistrationResultAttributes,
  SubjectPeriodAttributes,
  CompanyAttributes,
  TeacherAttributes,
} from '../../utils/constants';
import TooltipButton from '../shared/TooltipButton';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Tab,
  TableFooter,
  TablePagination,
  Tabs,
} from '@mui/material';
import RoomsFormContainer from '../../containers/RoomsFormContainer';
import PenIcon from '../icon/PenIcon';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import { RootState } from '../../store/configureStore';
import TablePaginationActions from '../shared/TablePaginationActions';
import SelectComponent from '../input/form/select';
import InputComponent from '../input/form/input';
import RoomStudentsTable from './RoomStudentsTable';
import ClassTimeTable from './ClassTimeDisplayTable';
import RoomSchedulesTable from './RoomSchedulesTable';
import { FETCH_KTWELVE_CURRICULUM } from '../../store/ktwelve_curriculums';
import { filter, find, head, map } from 'lodash';
import { KtwelveSubjectJson } from '../../store/ktwelve_subjects';
import { CompositionJson } from '../../store/compositions';
import { CompositionPeriodJson } from '../../store/composition_periods';
import { error } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import TabPanel from '../shared/TabPanel';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { FormulaJson } from '../../store/formulas';
import { ExamPlacementJson } from '../../store/exam_placements';
import { ExpandMore } from '@mui/icons-material';
import { FETCH_TEACHER } from '../../store/teachers';
import SubjectPeriodRegistrationTable from './SubjectPeriodRegistrationTable';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const FilterCss = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  & > span {
    margin-bottom: 14px;
  }
  & .inputArea {
    width: 10rem !important;
    padding-right: 3rem;
  }
`;

const ViewCss = css`
  width: inherit;
  min-height: 100%;
  height: fit-content;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  & .MuiBackdrop-root {
    position: inherit;
  };
  & .MuiPaper-root {
    background: none;
    box-shadow: none;
    overflow: hidden;
  },
`;
const TabCss = css`
  & .Mui-selected {
    background: #fdfeff;
    border-radius: 1rem 1rem 0 0;
  }
`;

const AccordionCss = css`
  background: inherit;
  box-shadow: none;
  border-bottom: 1px solid ${colors.grayBlue};
`;
const AccordionSummaryCss = css`
  display: flex;
  align-items: center;
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    width: inherit;
  }
`;

export interface SubjectRegistrationPeriodData extends SubjectPeriodRegistrationAttributes {
  exams: ExamAttributes[];
  registration_results: RegistrationResultAttributes[];
}

export interface SubjectPeriodData extends SubjectPeriodAttributes {
  subject_period_registrations: SubjectRegistrationPeriodData[];
  formulas: FormulaAttributes[];
  exam_placements: ExamPlacementAttributes[];
}

const KtwelveSubjectResults = (props: {
  ktwelve_subject: KtwelveSubjectAttributes;
  composition: CompositionData;
  room: RoomAttributes;
}) => {
  const { composition, ktwelve_subject, room } = props;
  let initial_current_composition_period = find(composition.composition_periods, (cp) => {
    const is_same_day_as_start_day = isSameDay(new Date(), new Date(cp.starts_at));
    const is_after_starts_at_day = isAfter(new Date(), new Date(cp.starts_at));
    const is_same_day_as_end_day = isSameDay(new Date(), new Date(cp.ends_at));
    const is_before_end_day = isBefore(new Date(), new Date(cp.ends_at));
    return is_same_day_as_start_day || (is_after_starts_at_day && is_same_day_as_end_day) || is_before_end_day;
  });
  if (!initial_current_composition_period) {
    initial_current_composition_period = head(composition.composition_periods) as CompositionPeriodData;
  }

  return (
    <>
      {map(composition.composition_periods, (cp) => {
        return (
          <Accordion
            defaultExpanded={initial_current_composition_period?.id === cp?.id}
            TransitionProps={{ unmountOnExit: true }}
            key={cp.id}
            css={AccordionCss}
          >
            <AccordionSummary css={AccordionSummaryCss}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <ExpandMore />
                <span>{cp.name}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <SubjectPeriodRegistrationTable
                ktwelve_subject={ktwelve_subject}
                room={room}
                initial_current_composition_period={cp}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export interface CompositionPeriodData extends CompositionPeriodAttributes {
  formulas: FormulaAttributes[];
  exam_placements: ExamPlacementAttributes[];
}

export interface CompositionData extends CompositionAttributes {
  composition_periods: CompositionPeriodData[];
}

interface StateCompanies extends CompanyAttributes {
  teachers: TeacherAttributes[];
}

const StudentResultsTable = (props: { room: RoomAttributes }) => {
  const { room } = props;
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
    account: { companies },
  } = state;

  const [composition, setComposition] = React.useState<CompositionData | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [ktwelveSubjects, setKtwelveSubjects] = React.useState<KtwelveSubjectAttributes[]>([]);
  const [tab, setTab] = React.useState('');
  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };
  const fetchKtwelveCurriculumData = React.useCallback(async () => {
    try {
      setLoading(true);
      const current_teacher = find(find(companies as StateCompanies[], (co: StateCompanies) => co.id === company)?.teachers, t => t.teacher_company_id.toString() === company.toString())
      const evaluate_teacher = profile.role === Role.TEACHER && current_teacher;
      let teacher_ktwelve_subject_ids = [] as string[];
      if (evaluate_teacher) {
        const teacher_ktwelve_subjects_response = await dispatch(
          FETCH_TEACHER.request({
            id: current_teacher.id,
            params: {
              filters: {
                include: 'ktwelve_subjects',
              },
            },
          }),
        );
        const {
          data: { included },
        } = teacher_ktwelve_subjects_response;
        teacher_ktwelve_subject_ids = map(
          filter(included, (incl) => incl.type === 'ktwelve_subjects'),
          (incl) => incl.id,
        );
      }

      const ktwelve_curriculum = await dispatch(
        FETCH_KTWELVE_CURRICULUM.request({
          id: room.ktwelve_curriculum_id,
          params: {
            filters: {
              include: [
                'composition',
                'composition_periods.exam_placements',
                'composition_periods.formulas',
                'ktwelve_subjects',
              ].join(','),
            },
          },
        }),
      );
      const {
        data: { included },
      } = ktwelve_curriculum;
      let ktwelve_subjects = map(
        filter(included, (incl) => incl.type === 'ktwelve_subjects') as KtwelveSubjectJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      if (evaluate_teacher) {
        ktwelve_subjects = filter(ktwelve_subjects, (ks) => teacher_ktwelve_subject_ids.includes(ks.id));
      }
      const included_composition = find(included, (incl) => incl.type === 'compositions') as CompositionJson;
      const composition = {
        id: included_composition.id,
        ...included_composition.attributes,
        composition_periods: map(
          filter(included, (incl) => incl.type === 'composition_periods') as CompositionPeriodJson[],
          (cp) => {
            const formulas = map(
              filter(
                included,
                (incl) =>
                  incl.type === 'formulas' &&
                  incl.attributes.formulable_type === FormulableType.COMPOSITION_PERIOD &&
                  incl.attributes.formulable_id === ~~cp.id,
              ) as FormulaJson[],
              (formula) => ({
                id: formula.id,
                ...formula.attributes,
              }),
            ) as FormulaAttributes[];

            const exam_placements = map(
              filter(
                included,
                (incl) =>
                  incl.type === 'exam_placements' &&
                  incl.attributes.exam_placeable_type === ExamPlaceableType.COMPOSITION_PERIOD &&
                  incl.attributes.exam_placeable_id === ~~cp.id,
              ) as ExamPlacementJson[],
              (formula) => ({
                id: formula.id,
                ...formula.attributes,
              }),
            ) as ExamPlacementAttributes[];
            return {
              id: cp.id,
              ...cp.attributes,
              formulas,
              exam_placements,
            };
          },
        ),
      };
      setComposition(composition);
      setKtwelveSubjects(ktwelve_subjects);
      setTab(head(ktwelve_subjects)?.id as string);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        error({
          message: 'Erro ao carregar dados da matriz curricular',
        }),
      );
    }
  }, []);

  const init = React.useCallback(async () => {
    await fetchKtwelveCurriculumData();
  }, []);

  React.useEffect(() => {
    init();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Tabs variant='fullWidth' css={TabCss} value={tab} onChange={handleTabChange}>
        {ktwelveSubjects.map((item) => {
          return <Tab key={item.subject_name} label={item.subject_name} value={item.id} />;
        })}
      </Tabs>
      {ktwelveSubjects.map((item) => {
        return (
          <TabPanel key={item.subject_name} index={item.id} value={tab}>
            <KtwelveSubjectResults ktwelve_subject={item} room={room} composition={composition as CompositionData} />
          </TabPanel>
        );
      })}
    </div>
  );
};

const RoomsTable = ({
  rooms,
  pagination,
  handleChangePageSize,
  handlePageChange,
  fetchRoomsMethod,
  companyFilterValue,
  setCompanyFilterValue,
  nameFilterValue,
  setNameFilterValue,
  setProductFilterValue,
  productFilterValue,
}: {
  rooms: RoomAttributes[];
  pagination: PaginationType;
  handlePageChange?: (_: any, newPage: number) => void;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setProductFilterValue: React.Dispatch<React.SetStateAction<string>>;
  handleChangePageSize?: (e: any) => void;
  fetchRoomsMethod: ({
    newPagination,
    company_filter,
    product_filter,
  }: {
    newPagination?: PaginationType;
    company_filter?: string;
    product_filter?: string;
  }) => Promise<void>;
  companyFilterValue: string;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  productFilterValue: string;
}) => {
  const [expandedRoomAssets, setExpandedRoomStudents] = React.useState<number[]>([]);
  const [expandedClassTimes, setExpandedClassTimes] = React.useState<number[]>([]);
  const [expandedRoomSchedules, setExpandedRoomSchedules] = React.useState<number[]>([]);
  const [expandedStudentResults, setExpandedStudentResults] = React.useState<number[]>([]);

  const [editRoom, setEditingRoom] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);

  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });

  return (
    <div css={ViewCss}>
      <span className='title'>Salas</span>
      <div css={FilterCss}>
        {is_above_school_director && (
          <SelectComponent
            placeholder='Selecionar Unidade'
            small
            options={companies_options}
            input={{
              value: companyFilterValue,
              onChange: (e: any) => {
                setCompanyFilterValue(e.target.value);
                fetchRoomsMethod({
                  newPagination: { ...defaultPagination },
                  company_filter: e.target.value,
                });
              },
            }}
          />
        )}
        <InputComponent
          placeholder={`Buscar por nome`}
          search
          small
          onSearch={() => {
            fetchRoomsMethod({});
          }}
          input={{
            value: nameFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setNameFilterValue(e.target.value);
            },
          }}
        />
        <InputComponent
          placeholder={`Buscar pelo produto`}
          search
          small
          onSearch={() => {
            fetchRoomsMethod({});
          }}
          input={{
            value: productFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setProductFilterValue(e.target.value);
            },
          }}
        />
      </div>
      <Table css={TableCss} size='small' aria-label='ktwelve_subjects'>
        <TableHead>
          <TableRow>
            {is_above_school_director && <TableCell align='center'>Unidade</TableCell>}
            <TableCell align='center'>Nome</TableCell>
            <TableCell align='center'>Produto</TableCell>
            <TableCell align='center'>Série</TableCell>
            {[Role.SUPERUSER, Role.PEDAGOGICAL_ADMIN, Role.SCHOOL_MANAGER, Role.COORDINATOR].includes(profile.role) &&
              <TableCell align='right'>
                {' '}
                <button disabled={editRoom.length > 0} onClick={() => setCreate(!create)} className='green small'>
                  <span> Adicionar Sala </span>
                </button>
              </TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} align='right'>
              <Collapse in={create} timeout='auto' unmountOnExit>
                <RoomsFormContainer
                  onSave={async () => {
                    await fetchRoomsMethod({ newPagination: defaultPagination });
                    setCreate(false);
                  }}
                  close_form={() => setCreate(false)}
                />
              </Collapse>
            </TableCell>
          </TableRow>
          {rooms.map((room) => {
            const close_editing_form = () => {
              setEditingRoom((current) => current.filter((item) => item !== ~~room.id));
            };
            return (
              <React.Fragment key={room.id}>
                <TableRow>
                  {is_above_school_director && <TableCell align='center'>{room.company_name}</TableCell>}
                  <TableCell align='center'>{room.name}</TableCell>
                  <TableCell align='center'>{room.product_name}</TableCell>
                  <TableCell align='center'>{room.ktwelve_degree_name}</TableCell>
                  <TableCell align='right'>
                    <TooltipButton
                      tooltipProps={{
                        title: editRoom.includes(~~room.id) ? 'Cancelar edição' : 'Editar Sala',
                        style: {
                          display: [
                            Role.SUPERUSER,
                            Role.PEDAGOGICAL_ADMIN,
                            Role.SCHOOL_MANAGER,
                            Role.COORDINATOR,
                          ].includes(profile.role)
                            ? 'initial'
                            : 'none',
                        },
                      }}
                      Icon={PenIcon}
                      iconButtonProps={{
                        disabled: create || (editRoom.length > 0 && !editRoom.includes(~~room.id)),
                        onClick: () =>
                          setEditingRoom((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomAssets.includes(~~room.id) ? 'Ocultar alunos' : 'Ver alunos',
                      }}
                      Icon={PiStudent}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedRoomStudents((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedClassTimes.includes(~~room.id) ? 'Ocultar horários' : 'Ver horários',
                      }}
                      Icon={CiViewTable}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedClassTimes((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomSchedules.includes(~~room.id)
                          ? 'Ocultar agendamentos de horário'
                          : 'Ver agendamentos de horário',
                        style: {
                          display: [
                            Role.SUPERUSER,
                            Role.PEDAGOGICAL_ADMIN,
                            Role.SCHOOL_MANAGER,
                            Role.COORDINATOR,
                          ].includes(profile.role)
                            ? 'initial'
                            : 'none',
                        },
                      }}
                      Icon={RiCalendarScheduleLine}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedRoomSchedules((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomSchedules.includes(~~room.id)
                          ? 'Ocultar notas e frequência'
                          : 'Ver notas e frequência',
                      }}
                      Icon={PiExam}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedStudentResults((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={editRoom.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomsFormContainer
                        onSave={async () => {
                          close_editing_form();
                          await fetchRoomsMethod({ newPagination: defaultPagination });
                        }}
                        close_form={close_editing_form}
                        room_id={~~room.id}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedRoomAssets.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomStudentsTable room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedClassTimes.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <ClassTimeTable room_id={room.id} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedRoomSchedules.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomSchedulesTable room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedStudentResults.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <StudentResultsTable room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
        {pagination && handlePageChange && handleChangePageSize && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                colSpan={5}
                count={pagination.totalCount}
                rowsPerPage={pagination.pageSize}
                page={pagination.pageNumber}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangePageSize}
                labelRowsPerPage='Itens Por página'
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                }
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </div>
  );
};

export default RoomsTable;
